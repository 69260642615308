<template>
  <div class="login h-screen flex flex-col">

    <section class="pt-8 pb-4">
      <img
        class="mx-auto mb-12"
        src="/img/logo-friso-login.png"
        srcset="/img/logo-friso-login.png,
          /img/logo-friso-login@2x.png 2x,
          /img/logo-friso-login@3x.png 3x"
      />

      <div class="text-center font-extrabold text-primary leading-5 text-lg mb-4">
        Este sitio web está destinado exclusivamente a los profesionales de la salud.
      </div>
      <div class="text-center text-primary leading-5">
        Si su acceso ya está concedido, introduzca los datos a continuación.<br/>
        Si usted es un profesional de la salud y no tiene las credenciales para este sitio web,<br/>
        <u>pregunte a su persona de contacto en PiSA</u>.
      </div>
    </section>

    <section class="w-96 mx-auto px-4 py-6 flex-1">
      <div class="mb-3">
        <input
          class="w-full py-2 px-4 rounded-full shadow-around focus:outline-none"
          :class="username.classes"
          v-model="username.value"
          type="text"
          placeholder="Usuario"
        />
        <div
          class="text-sm text-red-600"
          v-if="username.message"
          v-text="username.message"
        />
      </div>
      <div class="mb-3">
        <input
          class="w-full py-2 px-4 rounded-full shadow-around focus:outline-none"
          :class="password.classes"
          v-model="password.value"
          type="password"
          placeholder="Contraseña"
        />
        <div
          class="text-sm text-red-600"
          v-if="password.message"
          v-text="password.message"
        />
      </div>
      <div>
        <button
          class="w-full py-2 px-4 rounded-full uppercase border-2 bg-primary 
          border-secondary text-white font-bold text-sm focus:outline-none"
          @click="submit"
        >
          Descubrir
        </button>
        <div
          class="mt-2 text-sm text-red-600"
          v-if="submitErrorMessage"
          v-text="submitErrorMessage"
        />
      </div>
    </section>

    <section class="pb-4">
      <div class="text-primary text-sm font-bold text-center">&copy; {{ currentYear }} FrieslandCampina, todos los derechos reservados.</div>
      <div class="text-primary text-xs text-center">El contenido de esta página web, incluyendo, pero no limitado a nuestras marcas principales FRISO&reg; and FRISOLAC&reg;, es propiedad intelectual protegida de FrieslandCampina.</div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  setup() {
    const { dispatch, commit } = useStore();
    const router = useRouter();
    const currentYear = new Date().getFullYear();

    const submitErrorMessage = ref("");

    const username = reactive({
      value: "",
      classes: [""],
      message: ""
    });

    const password = reactive({
      value: "",
      classes: [""],
      message: ""
    });

    const submit = () => {
      username.classes = [];
      password.classes = [];

      // TODO: validate inputs

      dispatch("login", { username: username.value, password: password.value })
        .then(() => {
          router.push({ name: "Home" });
        })
        .catch(errs => {
          errs.forEach((err: any) => {
            if (!err.field) {
              submitErrorMessage.value = err.message;
              if (err.code === 11) {
                username.classes = ["border-red-600"];
                password.classes = ["border-red-600"];
              }
            }
          });
        });
    };

    return { submit, username, password, submitErrorMessage, currentYear };
  }
});
</script>

<style scoped lang="scss">
.login {
  background: rgb(45,140,196);
  background: linear-gradient(180deg, rgba(45,140,196,1) 0%, rgba(255,255,255,1) 50%, rgba(109,174,59,1) 100%);
  background-image: url('/img/login-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
